<template>
  <div class="pIitemTable">
    <iframe :src="checkUrl" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
// import { postAdminTicket } from "@/api/set";
export default {
  name: "signature",
  props: {},
  data() {
    return {
      checkUrl: ""
    };
  },
  created() {
    this.getUrl();
  },
  methods: {
    async getUrl() {
      this.$api.set.suppliercontracturl({qiyuesuo_contract_id:this.$route.query.id}).then(res=>{
        console.log(res,'获取详细数据')
      this.checkUrl = res.data ;
      })
      // let { url, params } = await postAdminTicket({
      //   admin_id: JSON.parse(sessionStorage.getItem("userInfo")).admin_id
      // });
      // let param = "";
      // for (let key in params) {
      //   if (key == "ticket") {
      //     param += "?" + key + "=" + params[key];
      //   } else {
      //     param += "&" + key + "=" + params[key];
      //   }
      // }
      // this.checkUrl = url + param;
      // console.log(param);
    }
  }
};
</script>

<style lang="scss">
.pIitemTable {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
</style>
